.announcement-block {
    padding-top: 16px;
    padding-bottom: 16px;
    color: var(--white);
    justify-content: space-between;
}

.announcement-close-btn {
    background-color: var(--white);
    position: relative;
    top: 5px;
}

.announcement-text {
    background: url('/images/warning_sign.svg') no-repeat 0 0;
    padding-left: 55px;
    width: calc(100% - 50px);
}

@media (max-width: 768px) {
    .announcement-text {
        background: none;
        padding-left: 0;
        font-size: 12px;
    }

    .announcement-close-btn {
        padding: 0;
        height: 30px;
        width: 30px;
        mask-size: 30px;
        -webkit-mask-size: 30px;
    }
}
