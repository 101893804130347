.footer-block {
    background-color: var(--deepBlue);
    color: var(--white);
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer-site-links {
    background: url('/images/logo_footer.svg') no-repeat calc(100% - 15px) 0;
}

.footer-block a {
    color: var(--white);
}

.footer-item {
    font-size: 1rem;
    margin: 0;
    padding-bottom: 17px;
}

.footer-copyrights {
    color: var(--blueGreen);
}

@media (max-width: 768px) {
    .footer-site-links {
        background: url('/images/logo_footer.svg') no-repeat 15px 0;
        padding-top: 50px;
    }

    .footer-site-links__block hr {
        background-color: var(--cofee);
        margin-bottom: 30px;
        margin-top: 0;
    }

    .footer-legal-info {
        flex-direction: column-reverse;
    }

    .footer-legal-links {
        justify-content: start !important;
        padding-bottom: 23px;
        display: block !important;
    }

    .footer-legal-links__item {
        font-size: .875rem;
        padding-bottom: 17px;
    }

    .footer-legal-links__separator {
        display: none;
    }

    .footer-copyrights {
        font-size: .75rem;
    }

    .footer-item {
        font-size: .875rem;
        margin: 0;
        padding-bottom: 17px;
    }
}
