.header {
    /* display: inline is used here to correctly place header menu as sticky */
    display: inline;
    position: relative;
}

@media (max-width: 1024px) {
    .header {
        display: block;
    }

    .header__regular-menu {
        display: none;
    }

    .header__hamburger-menu {
        display: block;
    }

    .header__hamburger-menu_visible {
        overflow: hidden;
        height: 100vh;
    }

    .header__hamburger-title {
        background-color: var(--cream);
        padding-top: 30px;
        padding-bottom: 30px;
        height: 84px;
        align-items: center;
        /*position: relative;*/
        position: sticky;
        z-index: 200;
    }

    .header__hamburger-btn {
        background-color: transparent;
        border: none;
        width: 30px;
        padding: 0;
    }

    .header__hamburger-btn hr {
        background-color: var(--blueGreen);
        border: none;
        float: right;
        height: 2px;
        width: 20px;
        margin-top: 8px;
        margin-bottom: 0;
    }

    .header__hamburger-btn hr:first-child,
    .header__hamburger-btn hr:last-child {
        width: 30px;
    }

    .header__hamburger-btn hr:first-child {
        margin-top: 0;
    }

    .header__hamburger-btn hr:last-child {
        margin-bottom: 0;
    }

    .header__hamburger-btn-close {
        background-color: var(--cofee);
    }

    .header__hamburger-menu-block {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 100;
        overflow: auto;
        top: 0;
        padding-top: 84px; /* height of header__hamburger-title */
    }

    .header__hamburger-menu-block-container {
        width: 100%;
        height: 100%;
    }

    .header__hamburger-menu-block-content {
        overflow: auto;
    }

    .header__hamburger-menu-block .header-menu__logo,
    .header__hamburger-menu-block .header-contacts .lang-selector {
        display: none;
    }

    .header__hamburger-menu-block .lang-selector {
        position: relative;
        justify-content: flex-end;
        padding: 15px 65px 0 80px;
    }

    .header__hamburger-menu-block .header-menu {
        background-color: transparent;
        display: block !important;
        position: relative;
        padding-top: 0 !important;
    }

    .header__hamburger-menu-block .header-menu__nav {
        margin-bottom: 20px !important;
    }

    .header__hamburger-menu-block .header-menu__nav a {
        display: block;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .header__hamburger-menu-block .contacts-block {
        display: block;
        margin-bottom: 20px;
    }

    .header__hamburger-menu-block .header-contacts {
        position: relative;
    }

    .header__hamburger-menu-block .header-contacts__block {
        display: block !important;
    }

    .header__hamburger-menu-overlay {
        background-color: var(--white);
        position: absolute;
        top: 0;
        left: 0;
        /*opacity: .9;*/
        height: 100%;
        width: 100%;
    }
}

@media (min-width: 1025px) {
    .header__regular-menu {
        /* display: inline is used here to correctly place header menu as sticky */
        display: inline;
    }

    .header__hamburger-menu {
        display: none;
    }
}

@media (max-width: 768px) {
    .header__hamburger-menu-block .lang-selector {
        padding: 15px 15px 0 30px;
    }
}

@media (max-width: 480px) {
    .header__hamburger-menu-block .client-request__btn {
        display: block;
        text-align: center;
        width: 100%;
    }

    .header__hamburger-menu-block .header-menu__nav a {
        font-size: .875rem;
    }
}
