.schedule-item {
    padding: 7px 0;
}

.schedule-item__weekday-name {
    padding-right: 10px;
}

.schedule-item__weekday-text {
    font-weight: 500;
}

@media (max-width: 1024px) {
    .schedule-item__weekday-text {
        background: url('/images/open_hours.svg') no-repeat 0 0;
        padding-left: 28px;
        padding-bottom: 2px;
    }

    .schedule-item__weekday-text_weekend {
        background-image: url('/images/closed_hours.svg');
    }
}

@media all and (max-width: 480px) {
    .schedule-item {
        font-size: .875rem;
    }
}
