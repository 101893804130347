:root {
    --deepBlue: #050A39;
    --blue: #070D47;
    --green: #048B8F;
    --blueGreen: #00B6BC;
    --yellow: #FFD706;
    --darkYellow: #F8CA08;
    --cofee: #B5AFAF;
    --cream: #EFEFED;
    --white: #FCF9F7;
    --ultraWhite: #FFFCFB;
    --clearWhite: #FFF;
    --red: #C80D0D;
    --black: #0C0909;

    --recommended-doctor-slider-item-width: 400px;
    --page-padding-x: 80px;
}

html, body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: var(--white);
    color: var(--deepBlue);
}

code {
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
    color: var(--blueGreen);
}

a {
    color: var(--green);
}

a:hover {
    color: var(--blueGreen);
}

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 3rem; /* 48px */
    line-height: 4.5rem; /* 72px */
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 2.25rem; /* 36px */
    line-height: 3.375rem; /* 54px */
}

h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem; /* 24px */
    line-height: 2.25rem; /* 36px */
}

.btn-orto-primary,
.btn-orto-secondary,
.btn-orto-emphasis,
.btn-orto-transparent {
    font-weight: 500;
    padding: 1rem 2rem;
    transition: all ease-in-out .3s;
    white-space: nowrap;
}

.btn-orto-primary:focus,
.btn-orto-secondary:focus,
.btn-orto-emphasis:focus,
.btn-orto-transparent:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--deepBlue);
}

.btn-orto-transparent {
    background-color: transparent;
    border: 1px solid var(--blueGreen);
    color: var(--blueGreen);
}

.btn-orto-transparent:hover, .btn-orto-transparent:focus {
    background-color: var(--blueGreen);
    border: 1px solid var(--blueGreen);
    color: var(--white);
    text-decoration: none;
}

.btn-orto-transparent:active {
    background-color: var(--green);
    border: 1px solid var(--blueGreen);
    color: var(--white);
    text-decoration: none;
    outline: none;
}

.btn-orto-primary {
    background-color: var(--blueGreen);
    border: 1px solid var(--blueGreen);
    color: var(--white);
}

.btn-orto-primary:hover, .btn-orto-primary:focus {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--white);
    text-decoration: none;
}

.btn-orto-primary:active {
    background-color: var(--green);
    border: 1px solid var(--blueGreen);
    color: var(--white);
    text-decoration: none;
    outline: none;
}

.btn-orto-secondary {
    background-color: var(--deepBlue);
    border: 1px solid var(--deepBlue);
    color: var(--white);
}

.btn-orto-secondary:hover, .btn-orto-secondary:focus {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: var(--white);
    text-decoration: none;
}

.btn-orto-secondary:active {
    background-color: var(--blue);
    border: 1px solid var(--blueGreen);
    color: var(--white);
    text-decoration: none;
    outline: none;
}

.btn-orto-emphasis {
    background-color: var(--yellow);
    border: 1px solid var(--yellow);
    color: var(--deepBlue);
}

.btn-orto-emphasis:hover, .btn-orto-emphasis:focus {
    background-color: var(--darkYellow);
    border: 1px solid var(--darkYellow);
    color: var(--deepBlue);
    text-decoration: none;
}

.btn-orto-emphasis:active {
    background-color: var(--darkYellow);
    border: 1px solid var(--deepBlue);
    color: var(--deepBlue);
    text-decoration: none;
    outline: none;
}

.btn-orto-close {
    mask-image: url('/images/close_sign.svg');
    -webkit-mask-image: url('/images/close_sign.svg');
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    height: 14px;
    width: 14px;
    border: none;
    color: var(--white);
    text-indent: -99999px;
}

.page-block-padding-x {
    padding-right: var(--page-padding-x) !important;
    padding-left: var(--page-padding-x) !important;
}

.page-block-padding-y {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

.page-block-margin-y {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
}

@media (max-width: 768px) {

    :root {
        --recommended-doctor-slider-item-width: 315px;
        --page-padding-x: 30px;
    }

    h1 {
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
    }

    h2 {
        font-size: 1.25rem; /* 20px */
        line-height: 1.5rem; /* 24px */
    }

    h3 {
        font-size: 1rem; /* 16px */
        line-height: 1.25rem; /* 20px */
    }

    p {
        font-size: .9375rem; /* 15px */
        line-height: 1.25rem; /* 20px */
    }

    .page-block-padding-x {
        padding-right: var(--page-padding-x) !important;
        padding-left: var(--page-padding-x) !important;
    }

    .page-block-padding-y {
        padding-top: 40px !important;
        padding-bottom: 50px !important;
    }

    .page-block-margin-y {
        margin-top: 40px !important;
        margin-bottom: 50px !important;
    }

    .btn-orto-primary,
    .btn-orto-secondary,
    .btn-orto-emphasis,
    .btn-orto-transparent {
        font-size: 1rem;
        line-height: 1.4;
        padding: .75rem 2rem;
    }
}
