.lang-selector {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lang-selector__button {
  background: none;
  border: none;
  color: var(--cofee);
  padding-left: 0;
  padding-right: 15px;
  transition: color ease-in-out .3s;
}

.lang-selector__button:hover {
  color: var(--green);
}

.lang-selector__button:active {
  color: var(--blueGreen);
  outline: none;
}

.lang-selector__button_active,
.lang-selector__button_active:hover,
.lang-selector__button_active:active {
  color: var(--deepBlue);
}

@media all and (max-width: 480px) {
  .lang-selector__button {
    font-size: .75rem;
  }
}
