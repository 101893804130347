.header-menu__nav-item.active,
.header-menu__nav-item.active:focus,
.header-menu__nav-item.active:hover {
    color: var(--green) !important;
    text-decoration: none;
}

.header-menu {
    background-color: var(--cream);
    position: sticky;
    top: 0;
    z-index: 100;
}

.header-menu__nav-item {
    color: var(--deepBlue);
    white-space: nowrap;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .header-menu__logo {
        margin-right: 1.5rem !important;
    }

    .client-request__btn {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .header-menu__nav-item {
        padding-inline: .5rem;
    }
}
