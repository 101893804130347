.contacts-block {
    padding: 0;
    margin: 0;
    list-style-type: none;

    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-gap: 0;
    grid-auto-flow: column;
    grid-auto-columns: auto auto;
}

.contacts-block_instagram {
    align-self: end;
}

.contacts-item {
    padding: 7px 55px 7px 0;
    display: flex;
    align-items: center;
}

.contacts-item img {
    margin-right: 10px;
}

.contacts-item a {
    color: var(--deepBlue);
}

@media all and (max-width: 480px) {
    .contacts-item {
        padding-right: 0;
    }

    .contacts-item a {
        font-size: .875rem;
    }
}
