.request-form__title {
    margin-bottom: 30px;
}

.request-form__item {
    margin-bottom: 40px !important;
}

.request-form__item-phone {
    margin-bottom: 35px !important;
}

.request-form__title_small-screen {
    display: none;
}

.request-form-image {
    padding: 33px 55px 53px 0;
    position: relative;
    width: 50%;
}

.request-form-image img {
    position: relative;
    z-index: 1;
    max-width: max-content;
    width: 100%;
}

.request-form-label-block.Mui-error .MuiSvgIcon-root {
    fill: var(--red);
}

.request-form-data {
    width: 50%;
}

.request-form-data .MuiFormLabel-asterisk {
    display: none;
}

.request-form-data .MuiFormLabel-root {
    color: var(--deepBlue)
}

.request-form-dots {
    background: url('/images/dot.svg') repeat 0 0;
    position: absolute;
    height: 270px;
    width: 130px;
}

.request-form-dots_top {
    top: 0;
    right: 125px;
}

.request-form-dots_bottom {
    left: 0;
    bottom: 0;
    height: calc(100% - 50px);
    width: 270px;
}

.request-form-label-block {
    margin-left: -9px;
    align-items: center;
}

.request-form-label {
    font-size: .875rem;
}

.request-form-label_optional {
    font-size: .875rem;
}

.request-form-btn {
    margin-right: 37px;
}

.request-form-data form {
    padding-right: 15%;
}

@media (max-width: 768px) {
    .request-form-block {
        display: block !important;
    }

    .request-form-day-select {
        display: block !important;
        margin-right: 0 !important;
    }
}

@media (max-width: 1024px) {
    .request-form-block {
        display: block !important;
    }

    .request-form-image {
        padding: 0 0 53px 0;
        width: 100%;
    }

    .request-form-data {
        width: 100%;
    }

    .request-form-data form {
        padding-right: 0;
    }

    .request-form-dots {
        display: none;
    }

    .request-form__title {
        display: none;
    }

    .request-form__title_small-screen {
        display: block;
    }
}

@media (max-width: 480px) {
    .request-form__action-btns {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .request-form-btn {
        width: 100%;
        margin-right: 0;
    }
}
