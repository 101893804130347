.modal-body {
    min-width: 500px;
    max-width: 700px;
}

.client-request__modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 210;
    overflow-y: scroll;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.client-request__modal-form {
    margin-bottom: 40px;
}

.client-request__modal-content {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    /*background: var(--white);*/
    overflow: auto;
    outline: none;
    max-width: 475px;
    margin: 0 auto;
}

.client-request__modal-custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cofee);
    opacity: .7;
    z-index: 110;
    backdrop-filter: blur(2px);
}

.client-request__modal-content .request-form-block {
    background-color: var(--ultraWhite) !important;
    padding: 20px 30px 40px !important;
    position: relative;
    z-index: 120;
}

.client-request__modal-title-image {
    height: 50px;
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    z-index: 130;

    background: url('/images/bee_min_green.svg') no-repeat 50%;
    background-size: 75px;
    transition: all ease .3s;
}

.client-request__modal-content .request-form__title {
    background-size: 75px;
    color: var(--blueGreen);
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-align: center;
    padding: 45px 20px 0;
    text-transform: uppercase;
    font-weight: 500;
}


.client-request__modal-content .request-form-label {
    font-size: .875rem;
}

.client-request__modal-content .btn-orto-close {
    position: absolute;
    background-color: var(--cofee);
    top: 23px;
    right: 33px;
    z-index: 130;
}

.client-request__modal-content .request-form-data {
    width: auto;
}

.client-request__modal-form .request-form-data form {
    padding-right: 0
}

.client-request__modal-content .request-form__action-btns {
    display: flex;
    justify-content: center;
    width: 100%;
}

.client-request__modal-content .mb-5 {
    margin-bottom: 2rem !important;
}

.client-request__modal-form.success .client-request__modal-title-image,
.client-request__modal-form.error .client-request__modal-title-image {
    height: 350px;
    mask-size: 415px;
    -webkit-mask-size: 415px;
    mask-position: 50% 30px;
    -webkit-mask-position: 50% 30px;
}

.client-request__modal-form.success .request-form-block,
.client-request__modal-form.error .request-form-block {
    text-align: center;
    padding: 300px 30px 140px !important;
}

.client-request__modal-form.error .client-request__modal-title-image {
    background-color: var(--red);
}

.client-request__modal-form.error .request-form__title {
    color: var(--red);
}

.MuiInputLabel-formControl {
    color: var(--cofee) !important;
}

.MuiInput-underline:before, .MuiOutlinedInput-notchedOutline {
    border-color: var(--cream) !important;
}

.MuiSelect-icon {
    fill: var(--blueGreen) !important;
}

.MuiOutlinedInput-input {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
}

.MuiSelect-selectMenu {
    background-color: var(--ultraWhite) !important;
}

@media (max-width: 768px) {
    .client-request__modal-title-image {
        background-size: 43px;
    }

    .client-request__modal-form {
        margin-bottom: 0;
    }

    .client-request__modal-form.success .client-request__modal-title-image {
        background-size: 255px;
    }

    .client-request__modal-content {
        top: 0;
    }

    .client-request__modal-content .request-form__title {
        font-size: 1rem;
        line-height: 1.125rem;
        padding: 42px 10px 0;
        margin-bottom: 20px;
    }

    .client-request__modal-content .request-form-label {
        font-size: .75rem;
    }

    .client-request__modal-content .request-form-btn_close {
        display: none;
    }

    .client-request__modal-form.success .client-request__modal-title-image,
    .client-request__modal-form.error .client-request__modal-title-image {
        height: 220px;
        mask-size: 255px;
        -webkit-mask-size: 255px;
    }

    .client-request__modal-form.success .request-form-block,
    .client-request__modal-form.error .request-form-block {
        padding: 185px 30px 140px !important;
    }
}
