.contacts-block {
    padding: 0;
    margin: 0;
    list-style-type: none;

    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-gap: 0;
    grid-auto-flow: column;
    grid-auto-columns: auto auto;
}

.contacts-item {
    padding: 7px 55px 7px 0;
    display: flex;
    align-content: baseline;
}

.contacts-item img {
    margin-right: 10px;
}

.contacts-item__multi-icon {
    display: flex;
    align-content: baseline;
}

.contacts-item .contacts-item__multi-icon img {
    margin-right: 5px;
}

.contacts-item .contacts-item__multi-icon img:last-child {
    margin-right: 10px;
}

.contacts-item a {
    color: var(--deepBlue);
}
